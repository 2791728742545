<template>
  <div>
    <b-modal
      id="modal_generar_informe_general"
      ok-only
      size="md"
      header-bg-variant="info"
      hide-footer
    >
      <template
        #modal-header
      >
        <div class="text-white">
          <strong class="float-left">
            Generar informe según fechas
          </strong></div>

      </template>
      <!-- BODY -->
      <b-row>
        <b-col
          md="12"
          sm="12"
        >
          <b-form-group
            label="Fecha inicio"
          >
            <b-form-datepicker
              v-model="fechaInicio"
              class="mb-2"
            />

          </b-form-group>
        </b-col>
        <b-col
          md="12"
          sm="12"
        >
          <b-form-group
            label="Fecha fin"
          >
            <b-form-datepicker
              v-model="fechaFin"
              class="mb-2"
            />

          </b-form-group>
        </b-col>

        <b-col
          md="12"
          sm="12"
        >
          <b-form-group
            label=""
          >
            <b-form-checkbox
              v-model="conFirma"
              unchecked-value="0"
              value="1"
              switch
              class="ml-3 mr-2 p-0"
            >
              <span
                class="p-0"
                style="font-size:14px"
              >Con firma</span>
            </b-form-checkbox>

          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between mt-2 border-top pt-1">
        <b-button
          type="button"
          variant="outline-danger"
          size="sm"
          @click="$bvModal.hide('modal_generar_informe_general')"
        >
          <feather-icon
            icon="XIcon"
            size="14"
          />
          Cancelar
        </b-button>
        <b-button
          :variant="`outline-success`"
          class="mr-2"
          @click="descargarInformeGeneral"
        >
          Descargar informe
          <feather-icon
            icon="CopyIcon"
            size="14"
          />
        </b-button>
      </div>
      <b-overlay
        :show="show"
        no-wrap
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BButton, BModal, BFormGroup, BFormDatepicker, BRow, BCol, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BModal,
    BFormGroup,
    BOverlay,
  },

  data() {
    return {
      conFirma: 0,
      show: false,
      codigo: '',
      fechaInicio: null,
      fechaFin: null,
    }
  },

  created() {
    this.$parent.$on('abrirModalGenerarInformeGeneral', this.abrirModalGenerarInformeGeneral)
  },
  methods: {
    abrirModalGenerarInformeGeneral(item) {
      this.codigo = item
      this.$bvModal.show('modal_generar_informe_general')
    },
    descargarInformeGeneral() {
      if (this.fechaInicio == null || this.fechaFin == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Complete todos los campos!',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
        return
      }

      if (this.fechaFin < this.fechaInicio) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡La fecha fin no puede ser menor que la fecha inicio!',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
        return
      }

      this.show = true
      this.$http.get(`/servicio-cultural-deportivo/${this.codigo}/global-actividad-periodo/reporte`,
        {
          params: {
            fecha_inicio: this.fechaInicio,
            fecha_fin: this.fechaFin,
            firma: this.conFirma,
          },
          responseType: 'blob', // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', 'InformeGeneral.pdf')
          document.body.appendChild(link)
          link.click()
          this.show = false

          this.$bvModal.hide('modal_generar_informe_general')
        })
    },
  },
}
</script>
