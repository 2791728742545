import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getActividades(ctx, { codigo, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/actividad`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActividadGlobal(ctx, { codigo, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/actividad`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateActividad(ctx, { codigo, id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-cultural-deportivo/${codigo}/actividad/${id}`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    deleteActividad(ctx, { codigo, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/actividad/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    getParticipantes(ctx, { codigo, actividadId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    addParticipante(ctx, { codigo, actividadId, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes`, data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    eliminarParticipante(ctx, { codigo, actividadId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/participantes/${id}`)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    /* evidencias */
    getEvidencias(ctx, { codigo, actividadId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvidencia(ctx, { codigo, actividadId, formData }) {
      // const config = { headers: { 'Content-Type': 'multipart/formdata' } }
      return new Promise((resolve, reject) => {
        axios
          .post(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias`, formData)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    deleteEvidencia(ctx, { codigo, actividadId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`servicio-cultural-deportivo/${codigo}/actividad/${actividadId}/evidencias/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
